import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Typography, Button, Dialog } from "@material-ui/core";
import NavBar from "./NavBar";
import TopBar from "./TopBar";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { setRun, setCurrentStep } from "src/slices/tour";
import { useSelector, useDispatch } from "src/store";
import useAuth from "src/hooks/useAuth";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        display: "flex",
        height: "100%",
        overflow: "hidden",
        width: "100%",
    },
    wrapper: {
        display: "flex",
        flex: "1 1 auto",
        overflow: "hidden",
        paddingTop: 64
    },
    contentContainer: {
        display: "flex",
        flex: "1 1 auto",
        overflow: "hidden",
    },
    content: {
        flex: "1 1 auto",
        height: "100%",
        overflow: "auto",
    },
    congratsIcon: {
        width: "18rem",
        marginTop: "-2rem",
    },
    congratsTitle: {
        paddingBottom: "2rem",
        paddingTop: "1rem",
    },
}));

const stepLength = 5;

const steps = [
    {
        title: `Step 1/${stepLength}`,
        content:
            "Select the device your design is targeted at. Desktop or Mobile.",
        target: "#deviceSelection",
        disableBeacon: true,
        placement: "right",
        spotlightClicks: true,
    },
    {
        title: `Step 2/${stepLength}`,
        content: "Upload your design!",
        target: "#designUpload",
        disableBeacon: true,
        placement: "auto",
        spotlightClicks: true,
    },
    {
        title: `Step 3/${stepLength}`,
        content: "Use Artificial Intelligence to analyze your image.", //"Let the Artificial Intelligence analyze your image.",
        target: "#makePredictionButton",
        disableBeacon: true,
        placement: "auto",
        placementBeacon: "left",
        spotlightClicks: true,
    },
    {
        title: `Step 4/${stepLength}`,
        content: (
            <div>
                <p>
                    To determine the relative attention for a particular element, simply draw a box around the area and press the <i style={{ fontStyle: "italic" }}>calculate</i> button.
                    <br />
                    <br />
                </p>
                <img
                    style={{ border: "none" }}
                    src="/static/gif/area-attention.gif"
                    alt="this slowpoke moves"
                    width="250"
                    border="1.5px"
                />
            </div>
        ),
        target: "#resultsContainer",
        disableBeacon: true,
        placement: "right-start",
        spotlightClicks: true,
    },
    {
        title: `Step 5/${stepLength}`,
        content:
            "Other analysis results, such as the Focus Map and the Gazeplot, can be displayed via the menu bar. In order to access the original image simply deselect all tabs.",
        target: "#tabContainer",
        disableBeacon: true,
        placement: "right-start",
        spotlightClicks: true,
    },
];

const DashboardLayout = ({ children }) => {
    const classes = useStyles();
    const { updateLastTourStep } = useAuth();
    const [isMobileNavOpen, setMobileNavOpen] = useState(false);
    const { currentStep, run } = useSelector((state) => state.tour);
    const dispatch = useDispatch();
    const [congratsOpen, setCongratsOpen] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const handleTourCallback = (data) => {
        const { action, status, index, type } = data;
        if (
            /*[EVENTS.TARGET_NOT_FOUND].includes(type) ||*/
            type === EVENTS.STEP_AFTER &&
            action === ACTIONS.NEXT &&
            index === currentStep &&
            [0, 3, 4].includes(index)
        ) {
            switch (index) {
                case 4: {
                    // tour is finished - STATUS.FINISHED is not beeing called, because index is lagging
                    dispatch(setRun(false));
                    setCongratsOpen(true);
                    break;
                }
                default:
            }

            // Update state to advance the tour
            dispatch(setCurrentStep(index + 1));
            updateLastTourStep(index + 1);
        } else if (action === ACTIONS.CLOSE) {
            dispatch(setRun(false));
        } else if ([STATUS.FINISHED].includes(status)) {
            // Need to set our running state to false, so we can restart if we click start again.
            dispatch(setRun(false));
        }
    };

    const handleCongratulationsClose = () => {
        setCongratsOpen(false);
    };

    useEffect(() => {
        if (run) {
            document.body.style.overflow = "hidden"
        } else {
            document.body.style.overflow = "auto"
        }
    }, [run])

    return (
        <div className={classes.root}>
            <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
            <NavBar
                onMobileClose={() => setMobileNavOpen(false)}
                openMobile={isMobileNavOpen}
            />
            <div className={classes.wrapper}>
                <div className={classes.contentContainer}>
                    <div id="dashboardContent" className={classes.content}>
                        <React.Fragment>
                            {children}
                            <Joyride
                                styles={{
                                    options: {
                                        zIndex: 1400,
                                        primaryColor: "rgb(56, 113, 241)",
                                        width: 280
                                    },
                                    tooltipContent: {
                                        padding: "20px 0px 0px 0px",
                                    },
                                    tooltip: {
                                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                                        fontSize: 14
                                    },
                                    tooltipTitle: {
                                        fontSize: 16
                                    },
                                    buttonNext: {
                                        display: [0, 3, 4, 5, 6, 7, 9].includes(
                                            currentStep
                                        )
                                            ? "inline"
                                            : "none",
                                        outlineColor: "rgb(56, 113, 241)",
                                    },
                                }}
                                continuous={[0, 3, 4, 5, 6, 7, 9].includes(
                                    currentStep
                                )}
                                debug={false}
                                disableOverlay={true}
                                disableCloseOnEsc={true}
                                disableOverlayClose={true}
                                hideBackButton={true}
                                steps={steps}
                                stepIndex={currentStep}
                                run={run}
                                callback={handleTourCallback}
                                disableScrollParentFix={true}
                                disableScrolling={false}
                                locale={{
                                    last: "Finish Tour",
                                }}
                            />
                            <Dialog
                                fullWidth
                                maxWidth="xs"
                                fullScreen={fullScreen}
                                onClose={handleCongratulationsClose}
                                aria-labelledby="simple-dialog-title"
                                open={congratsOpen}
                            >
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignContent="center"
                                    alignItems="center"
                                    justifyContent="center"
                                    padding="2rem"
                                >
                                    <img
                                        className={classes.congratsIcon}
                                        alt="Product Tour Finished"
                                        src="/static/tour_finished_icon.png"
                                    />
                                    <Typography align="center" variant="h3">
                                        Congratulations
                                    </Typography>
                                    <Typography
                                        className={classes.congratsTitle}
                                        align="center"
                                        variant="body1"
                                    >
                                        You have successfully completed the
                                        product tour and analyzed your first
                                        design
                                    </Typography>
                                    <Button
                                        color="secondary"
                                        size="medium"
                                        variant="contained"
                                        onClick={handleCongratulationsClose}
                                    >
                                        Continue
                                    </Button>
                                </Box>
                            </Dialog>
                        </React.Fragment>
                    </div>
                </div>
            </div>
        </div>
    );
};

DashboardLayout.propTypes = {
    children: PropTypes.node,
};

export default DashboardLayout;
