import React, { createContext, useEffect, useReducer } from "react";
import jwtDecode from "jwt-decode";
import SplashScreen from "src/components/SplashScreen";
import axios from "src/utils/axios";
import { API_BASE_URL } from "src/config";

const initialAuthState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
    loading: false,
};

const isValidToken = (accessToken) => {
    if (!accessToken) {
        return false;
    }

    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
};

const setSession = (accessToken) => {
    if (accessToken) {
        localStorage.setItem("accessToken", accessToken);
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
        localStorage.removeItem("accessToken");
        delete axios.defaults.headers.common.Authorization;
    }
};

const reducer = (state, action) => {
    switch (action.type) {
        case "INITIALISE": {
            const { isAuthenticated, user } = action.payload;

            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
            };
        }
        case "LOGIN": {
            const { user } = action.payload;

            return {
                ...state,
                isAuthenticated: true,
                loading: false,
                user,
            };
        }
        case "LOGOUT": {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            };
        }
        case "LOADING": {
            const { loading } = action.payload;

            return {
                ...state,
                loading
            };
        }
        case "REGISTER": {
            const { user } = action.payload;

            return {
                ...state,
                isAuthenticated: true,
                user,
            };
        }
        case "PROFILE_UPDATE": {
            const { updatedValues } = action.payload;

            const user = {
                ...state.user,
                ...updatedValues,
            };

            return {
                ...state,
                user,
            };
        }
        case "APIKEY_UPDATE": {
            const { apiKey } = action.payload;

            const user = {
                ...state.user,
                apiKey,
            };

            return {
                ...state,
                user,
            };
        }
        case "REDUCE_CREDITS": {
            const user = {
                ...state.user,
                company: {
                    ...state.user.company,
                    creditsLeft: state.user.company.creditsLeft - 1,
                    creditsUsed: state.user.company.creditsUsed + 1
                }
            };

            return {
                ...state,
                user,
            };
        }
        case "UPDATE_TOUR_STEP": {
            const { lastTourStep } = action.payload;

            const user = {
                ...state.user,
                lastTourStep,
            };

            return {
                ...state,
                user,
            };
        }
        case "UPDATE_SUBSCRIPTION": {
            const { company, expiryStatus, features } = action.payload;

            const user = {
                ...state.user,
                expiryStatus,
                features,
                company: {
                    ...state.user.company,
                    ...company
                }
            };

            return {
                ...state,
                user,
            };
        }
        default: {
            return { ...state };
        }
    }
};

const AuthContext = createContext({
    ...initialAuthState,
    method: "JWT",
    login: () => Promise.resolve(),
    googleLogin: () => Promise.resolve(),
    logout: () => {},
    register: () => Promise.resolve(),
    registerViaInvite: () => Promise.resolve(),
    updateProfile: () => Promise.resolve(),
    updateAPIKey: () => Promise.resolve(),
    reduceCredits: () => {},
    updateLastTourStep: () => Promise.resolve(),
    updateLoading: () => Promise.resolve()
});

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialAuthState);

    const login = async (email, password) => {
        const response = await axios.post("/api/auth", { email, password });
        const { token } = response.data;

        if (token) {
            setSession(token);
            const response = await axios.get("/api/auth");
            const { user } = response.data;

            if (!user.avatar) {
                user.avatar = `${API_BASE_URL}/avatars/100/${user._id}`;
            }

            dispatch({
                type: "LOGIN",
                payload: {
                    user,
                },
            });
        } else {
            throw new Error("No token given!");
        }
    };

    const googleLogin = async (code) => {

        dispatch({
            type: "LOADING",
            payload: {
                loading: true
            }
        });

        const response = await axios.post("/api/auth/google", { code: code });
        const { token } = response.data;

        if (token) {
            setSession(token);
            const response = await axios.get("/api/auth");
            const { user } = response.data;

            if (!user.avatar) {
                user.avatar = `${API_BASE_URL}/avatars/100/${user._id}`;
            }

            dispatch({
                type: "LOGIN",
                payload: {
                    user,
                },
            });
        } else {
            throw new Error("No token given!");
        }
    }

    const logout = () => {
        setSession(null);
        dispatch({ type: "LOGOUT" });
    };

    const register = async (email, firstName, lastName, password, captchaToken) => {
        const response = await axios.post("/api/users", {
            email,
            firstName,
            lastName,
            password,
            captchaToken
        });
        const { token } = response.data;

        setSession(token);

        const authResponse = await axios.get("/api/auth");
        const { user } = authResponse.data;

        if (!user.avatar) {
            user.avatar = `${API_BASE_URL}/avatars/100/${user._id}`;
        }

        dispatch({
            type: "REGISTER",
            payload: {
                user,
            },
        });
    };

    const registerViaInvite = async (email, firstName, lastName, password, captchaToken) => {
        const response = await axios.post("/api/users/invite", {
            email,
            firstName,
            lastName,
            password,
            captchaToken
        });
        const { token } = response.data;

        setSession(token);

        const authResponse = await axios.get("/api/auth");
        const { user } = authResponse.data;

        if (!user.avatar) {
            user.avatar = `${API_BASE_URL}/avatars/100/${user._id}`;
        }

        dispatch({
            type: "REGISTER",
            payload: {
                user,
            },
        });
    };

    const updateProfile = async (values) => {
        await axios.post("/api/users/profile", values);

        dispatch({
            type: "PROFILE_UPDATE",
            payload: {
                updatedValues: values,
            },
        });
    };

    const updateAPIKey = async () => {
        const response = await axios.get("/api/users/update-api-key");

        dispatch({
            type: "APIKEY_UPDATE",
            payload: response.data,
        });
    };

    const reduceCredits = () => {
        dispatch({
            type: "REDUCE_CREDITS",
        });
    };

    const updateLastTourStep = async (step) => {
        await axios.post("api/users/update-last-tour-step", {
            step: step,
        });

        dispatch({
            type: "UPDATE_TOUR_STEP",
            payload: { lastTourStep: step },
        });
    };

    const updateSubscription = async (newData) => {
        dispatch({
            type: "UPDATE_SUBSCRIPTION",
            payload: newData,
        });
    };

    const updateLoading = async (loading) => {
        dispatch({
            type: "LOADING",
            payload: {
                loading: loading
            }
        });
    };

    useEffect(() => {
        const initialise = async () => {
            try {
                const accessToken = window.localStorage.getItem("accessToken");

                if (accessToken && isValidToken(accessToken)) {
                    setSession(accessToken);

                    const response = await axios.get("/api/auth");
                    const { user } = response.data;

                    if (!user.avatar) {
                        user.avatar = `${API_BASE_URL}/avatars/100/${user._id}`;
                    }

                    dispatch({
                        type: "INITIALISE",
                        payload: {
                            isAuthenticated: true,
                            user,
                        },
                    });
                } else {
                    dispatch({
                        type: "INITIALISE",
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: "INITIALISE",
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                });
            }
        };

        initialise();
    }, []);

    if (!state.isInitialised) {
        return <SplashScreen />;
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: "JWT",
                login,
                googleLogin,
                logout,
                register,
                registerViaInvite,
                updateProfile,
                updateAPIKey,
                reduceCredits,
                updateLastTourStep,
                updateSubscription,
                updateLoading
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
