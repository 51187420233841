import { createSlice } from "@reduxjs/toolkit";
import axios from "src/utils/axios";

const initialState = {
    isLoaded: false,
    error: "",
    prediction: {},
    predictionImageData: {},
};

const slice = createSlice({
    name: "prediction",
    initialState,
    reducers: {
        loadPrediction(state) {
            state.isLoaded = false;
        },
        predictionSuccess(state, action) {
            state.prediction = action.payload;
            state.isLoaded = true;
            state.error = "";
        },
        setPredictionData(state, action) {
            state.predictionImageData = action.payload;
        },
        removePredictionImageData(state, action) {
            state.predictionImageData = {};
        },
    },
});

export const reducer = slice.reducer;

export const createPrediction = (predictionData) => async (dispatch) => {
    const response = await axios.post("/api/predictions", predictionData);
    dispatch(slice.actions.predictionSuccess(response.data));
    return response.data
};

export const storePredictionImageData = (imageData) => (dispatch) => {
    dispatch(slice.actions.setPredictionData(imageData));
};

export const removePredictionImageData = () => (dispatch) => {
    dispatch(slice.actions.removePredictionImageData());
};
