import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    editMode: false,
    predictionId: null,
};

const slice = createSlice({
    name: "reporting",
    initialState,
    reducers: {
        setEditMode(state, action) {
            state.editMode = action.payload;
        },
        setPredictionId(state, action) {
            state.predictionId =  action.payload;
        }
    },
});

export const reducer = slice.reducer;

export const setEditMode = (edit) => dispatch => {
    dispatch(slice.actions.setEditMode(edit));
};

export const setPredictionId = (id) => dispatch => {
    dispatch(slice.actions.setPredictionId(id));
};
