import React from "react";
import PropTypes from "prop-types";
import { Box, Typography, Link, makeStyles } from "@material-ui/core";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";

const useStyles = makeStyles((theme) => ({
    notificationText: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    upgradeLink: {
        color: "rgb(56, 113, 241, 0.8)",
    },
}));

const MessageStripe = ({ onLinkClick, message, linkTitle }) => {
    const classes = useStyles();
    
    return (
        <Box display="flex" alignItems="center">
            <NotificationsNoneOutlinedIcon color="primary" />
            <Typography
                color="primary"
                className={classes.notificationText}
                variant="body2"
            >
                {message}
            </Typography>
            <Link
                component="button"
                variant="body2"
                className={classes.upgradeLink}
                onClick={onLinkClick}
            >
                {linkTitle}
            </Link>
        </Box>
    );
};

MessageStripe.propTypes = {
    onLinkClick: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired,
    linkTitle: PropTypes.string.isRequired,
};

export default MessageStripe;
