import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';

const initialState = {
    isLoaded: false,
    error: '',
    products: {},
    limitCurrency: null
};

const slice = createSlice({
    name: 'plans',
    initialState,
    reducers: {
        loadPlans(state) {
            state.isLoaded = false;
        },
        plansSuccess(state, action) {
            state.products = action.payload.plans;
            state.limitCurrency = action.payload.limitCurrency;
            state.isLoaded = true;
            state.error = '';
        },
        plansFailure(state, action) {
            state.error = action.payload;
            state.isLoaded = true;
        }
    }
});

export const reducer = slice.reducer;

export const getPlans = () => async dispatch => {
    try {
        const { data } = await axios.get(`api/stripe/get-pricing-plans`);
        dispatch(slice.actions.plansSuccess(data));
    } catch (error) {
        dispatch(slice.actions.plansFailure(error));
    }
}