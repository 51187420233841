import React from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
    AppBar,
    Box,
    Hidden,
    IconButton,
    Toolbar,
    makeStyles,
    SvgIcon,
} from "@material-ui/core";
import { Menu as MenuIcon } from "react-feather";
import Logo from "src/components/Logo";
import { THEMES } from "src/constants";
import Account from "./Account";
import useAuth from "src/hooks/useAuth";

import { useHistory } from "react-router-dom";
import MessageStripe from "./MessageStripe";

const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: theme.zIndex.drawer + 100,
        ...(theme.name === THEMES.LIGHT
            ? {
                  boxShadow: "rgba(43, 83, 135, 0.08) 0px 3px 8px 0px;",
                  backgroundColor: "#fff",
              }
            : {}),
        ...(theme.name === THEMES.ONE_DARK
            ? {
                  backgroundColor: theme.palette.background.default,
              }
            : {}),
    },
    toolbar: {
        minHeight: 64,
    },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
    const classes = useStyles();
    const { user } = useAuth();
    const history = useHistory();

    const handleUpgradePlan = () => {
        // create new plan
        history.push("/new-plan");
    };

    const getInfoBar = () => {
        let expiryStatus = user.expiryStatus;
        if(!["ADMIN", "OWNER"].includes(user.role)) return;
        if (expiryStatus && expiryStatus.planHasExpired) {
            let bTrial = user.company.plan === "trial";
            return (
                <MessageStripe
                    onLinkClick={handleUpgradePlan}
                    message={`Your ${bTrial ? "trial" : "plan"} has expired!`}
                    linkTitle={bTrial ? "Upgrade your plan" : "Renew your plan"}
                />
            );
        } else if (
            expiryStatus &&
            expiryStatus.daysTillTrialExpiry > 0 &&
            expiryStatus.daysTillTrialExpiry < 5
        ) {
            return (
                <MessageStripe
                    onLinkClick={handleUpgradePlan}
                    message={`You have only ${expiryStatus.daysTillTrialExpiry} day(s) left in your trial!`}
                    linkTitle="Upgrade your plan"
                />
            );
        }
    };

    return (
        <AppBar className={clsx(classes.root, className)} {...rest}>
            <Toolbar className={classes.toolbar}>
                <Hidden lgUp>
                    <IconButton color="inherit" onClick={onMobileNavOpen}>
                        <SvgIcon fontSize="small" color="primary">
                            <MenuIcon />
                        </SvgIcon>
                    </IconButton>
                </Hidden>
                <Hidden mdDown>
                    <RouterLink to="/">
                        <Logo style={{ width: 90 }} />
                    </RouterLink>
                </Hidden>
                <Box ml={2} flexGrow={1} display="flex" justifyContent="center">
                    {getInfoBar()}
                </Box>
                <Box ml={2}>
                    <Account />
                </Box>
            </Toolbar>
        </AppBar>
    );
};

TopBar.propTypes = {
    className: PropTypes.string,
    onMobileNavOpen: PropTypes.func,
};

TopBar.defaultProps = {
    onMobileNavOpen: () => {},
};

export default TopBar;
