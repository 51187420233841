export const getErrorMessages = (err) => {
    let messages = [];
    if (err.response && err.response.data) {
        if (err.response.data.errors) {
            err.response.data.errors.forEach((error) => {
                if (error.msg) {
                    messages.push(error.msg)
                }
            });
        } else if (err.response.data.msg) {
            messages.push(err.response.data.msg);
        } else if (err.response.data.message) {
            messages.push(err.response.data.message);
        } else {
            messages.push(err.response.data);
        }
    } else if (err.message) {
        messages.push(err.message);
    }
    return messages;
}

const handleError = (err, enqueueSnackbar) => {
    if (!err || !enqueueSnackbar) return;
    let snachkbarMessages = getErrorMessages(err);
    snachkbarMessages.forEach(message => {
        enqueueSnackbar(message, {
            variant: "error",
        });
    })
    console.error(err);
    return snachkbarMessages;
};

export default handleError;
