/* eslint-disable no-use-before-define */
import React, { useEffect, Fragment, useState } from "react";
import clsx from 'clsx';
import { useLocation, matchPath } from "react-router-dom";
import { Link as RouterLink, useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import ExtensionIcon from "@material-ui/icons/Extension";
import {
    Box,
    Divider,
    Drawer,
    Hidden,
    Link,
    List,
    Typography,
    makeStyles,
} from "@material-ui/core";
import {
    Folder as FolderIcon,
    Layout as LayoutIcon,
} from "react-feather";
import AIIcon from "../../../icons/AIIcon";
import ComparisonIcon from "src/icons/ComparisonIcon";
import Logo from "src/components/Logo";
import NavItem from "./NavItem";
import handleError from "./../../../utils/handleError";
import { useSnackbar } from "notistack";
import useAuth from "src/hooks/useAuth";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import IconButton from '@material-ui/core/IconButton';
import useSettings from "src/hooks/useSettings";

const sections = [
    {
        title: "Dashboard",
        icon: LayoutIcon,
        href: "/",
    },
    {
        title: "Projects",
        icon: FolderIcon,
        href: "/projects",
    },
    {
        title: "Analyses",
        icon: AIIcon,
        href: "/predictions",
    },
    {
        title: "Comparisons",
        icon: ComparisonIcon,
        href: "/comparisons"
    },
    {
        title: "Plugins",
        icon: ExtensionIcon,
        href: "/plugins",
    }
];

function renderNavItems({ items, pathname, depth = 0, showTitles = true }) {
    return (
        <List disablePadding>
            {items.reduce(
                (acc, item) =>
                    reduceChildRoutes({ acc, item, pathname, depth, showTitles }),
                []
            )}
        </List>
    );
}

function reduceChildRoutes({ acc, pathname, item, depth, showTitles }) {
    const key = item.title + depth;

    if (item.items) {
        const selected = matchPath(pathname, {
            path: item.href,
            exact: false,
        });

        acc.push(
            <NavItem
                depth={depth}
                icon={item.icon}
                info={item.info}
                key={key}
                open={Boolean(selected)}
                title={item.title}
                showTitle={!!showTitles}
            >
                {renderNavItems({
                    depth: depth + 1,
                    pathname,
                    items: item.items,
                })}
            </NavItem>
        );
    } else {
        acc.push(
            <NavItem
                depth={depth}
                href={item.href}
                icon={item.icon}
                info={item.info}
                key={key}
                title={item.title}
                showTitle={!!showTitles}
            />
        );
    }

    return acc;
}

const drawerWidth = 220;

const useStyles = makeStyles(theme => ({
    mobileDrawer: {
        width: drawerWidth,
    },
    avatar: {
        cursor: "pointer",
        width: 64,
        height: 64,
    },
    guideLink: {
        fontSize: "0.95rem",
        marginTop: "0.15rem",
        "&:hover": {
            cursor: "pointer",
        },
    },
    guidesSectionTitle: {
        fontSize: "1rem",
        marginBottom: "0.15rem",
    },
    desktopDrawer: {
        top: 64,
        height: "calc(100% - 64px)",
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const { updateLastTourStep } = useAuth();
    const { enqueueSnackbar } = useSnackbar();
    const { settings, saveSettings } = useSettings();
    const [ keepClosed, setKeepClosed ] = useState(false);

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
        if(location?.pathname?.startsWith("/comparison/") || location?.pathname?.startsWith("/prediction/")) {
            setKeepClosed(true);
        } else {
            setKeepClosed(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    useEffect(() => {

    }, [])

    const handleRetakeTour = async () => {
        try {
            history.push("/predictions/create");
            await updateLastTourStep(100);
        } catch (err) {
            handleError(err, enqueueSnackbar);
        }
    };

    const handleDrawerClose = () => {
        saveSettings({ ...settings, drawerOpen: !settings.drawerOpen });
        setKeepClosed(false);
    };

    const getDrawerOpen = () => {
        if(keepClosed) {
            return false;
        } else {
            return settings.drawerOpen;
        }
    }

    const content = (mobile) => (
        <Box height="100%" display="flex" flexDirection="column">
            <PerfectScrollbar options={{ suppressScrollX: true }}>
                <Hidden lgUp>
                    <Box p={2} display="flex" justifyContent="start" ml={1}>
                        <RouterLink to="/">
                            <Logo style={{ height: 50, width: 90 }} />
                        </RouterLink>
                    </Box>
                </Hidden>
                <Box p={2}>
                    {renderNavItems({
                        items: sections,
                        pathname: location.pathname,
                        showTitles: mobile ? true : getDrawerOpen()
                    })}
                </Box>
                {(mobile || getDrawerOpen()) && (
                    <Fragment>
                        <Divider />
                        <Box p={2}>
                            <Box
                                p={2}
                                borderRadius="borderRadius"
                                bgcolor="background.dark"
                                display="flex"
                                flexDirection="column"
                            >
                                <Typography
                                    className={classes.guidesSectionTitle}
                                    variant="h6"
                                    color="textPrimary"
                                >
                                    Guides
                                </Typography>
                                <Link
                                    className={classes.guideLink}
                                    variant="subtitle1"
                                    color="secondary"
                                    rel="noopener"
                                    target="_blank"
                                    href="https://www.clueify.com/studys/Clueify_Accuracy.pdf"
                                >
                                    Accuracy Study
                                </Link>
                                <Link
                                    className={classes.guideLink}
                                    variant="subtitle1"
                                    color="secondary"
                                    rel="noopener"
                                    onClick={handleRetakeTour}
                                >
                                    Retake Tour
                                </Link>
                            </Box>
                        </Box>
                    </Fragment>
                )}
            </PerfectScrollbar>
            <Hidden mdDown>
                <Divider />
                <Box display="flex" justifyContent={getDrawerOpen() ? "flex-end" : "center"}>
                    <IconButton onClick={handleDrawerClose}>
                        {!getDrawerOpen() ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </Box>
            </Hidden>
        </Box>
    );

    return (
        <Fragment>
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    classes={{ paper: classes.mobileDrawer }}
                    onClose={onMobileClose}
                    open={openMobile}
                    variant="temporary"
                >
                    {content(true)}
                </Drawer>
            </Hidden>
            <Hidden mdDown>
                <Drawer
                    anchor="left"
                    className={clsx(classes.desktopDrawer, {
                        [classes.drawerOpen]: getDrawerOpen(),
                        [classes.drawerClose]: !getDrawerOpen(),
                    })}
                    classes={{
                        paper: clsx(classes.desktopDrawer, {
                            [classes.drawerOpen]: getDrawerOpen(),
                            [classes.drawerClose]: !getDrawerOpen(),
                        }),
                    }}
                    open
                    variant="persistent"
                >
                    {content(false)}
                </Drawer>
            </Hidden>
        </Fragment >
    );
};

NavBar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool,
};

export default NavBar;
