import { combineReducers } from '@reduxjs/toolkit';
import { reducer as predictionReducer } from 'src/slices/prediction';
import { reducer as projectReducer } from 'src/slices/project';
import { reducer as tourReducer } from 'src/slices/tour';
import { reducer as planReducer } from 'src/slices/plan';
import { reducer as reportingReducer } from 'src/slices/reporting';

const rootReducer = combineReducers({
  prediction: predictionReducer,
  project: projectReducer,
  tour: tourReducer,
  plans: planReducer,
  reporting: reportingReducer
});

export default rootReducer;
