import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

const GuestGuard = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  if (isAuthenticated) {
    if(location.search) {
        const sSection = new URLSearchParams(location.search).get("section");
        if(sSection === "plugins") {
            return <Redirect to={"/plugins"} />;
        }
    }
    return <Redirect to={location.state?.referrer || "/"} />;
  }

  return (
    <>
      {children}
    </>
  );
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
