import React from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { create } from "jss";
import rtl from "jss-rtl";
import { SnackbarProvider } from "notistack";
import { jssPreset, StylesProvider, ThemeProvider } from "@material-ui/core";
import GlobalStyles from "src/components/GlobalStyles";
import ScrollReset from "src/components/ScrollReset";
import GoogleAnalytics from "src/components/GoogleAnalytics";
import { AuthProvider } from "src/contexts/JWTAuthContext";
import useSettings from "src/hooks/useSettings";
import { createTheme } from "src/theme";
import routes, { renderRoutes } from "src/Routes";
import { THEMES } from "src/constants";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GOOGLE_CLIENT_ID } from "src/config";

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();

const App = () => {
    const { settings } = useSettings();

    const theme = createTheme({
        direction: settings.direction,
        responsiveFontSizes: settings.responsiveFontSizes,
        theme: THEMES.LIGHT,
    });

    return (
        <ThemeProvider theme={theme}>
            <StylesProvider jss={jss}>
                <SnackbarProvider dense maxSnack={3}>
                    <Router history={history}>
                        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                            <AuthProvider>
                                <GlobalStyles />
                                <ScrollReset />
                                <GoogleAnalytics />
                                {renderRoutes(routes)}
                            </AuthProvider>
                        </GoogleOAuthProvider>
                    </Router>
                </SnackbarProvider>
            </StylesProvider>
        </ThemeProvider>
    );
};

export default App;
