import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "react-perfect-scrollbar/dist/css/styles.css";
import "prismjs/prism";
import "prismjs/components/prism-bash";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-jsx";
import "src/assets/css/prism.css";
import "src/assets/css/nprogress.css"
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { enableES5 } from "immer";
import * as serviceWorker from "src/serviceWorker";
import store from "src/store";
import { SettingsProvider } from "src/contexts/SettingsContext";
import App from "src/App";
import { Helmet } from "react-helmet";
import { hotjar } from 'react-hotjar';

enableES5();

ReactDOM.render(
    <Provider store={store}>
        <SettingsProvider>
            <Helmet>
                <script type="text/javascript">{`
                (function(w,d,v3){
                w.chaportConfig = {
                  appId : '5f73219596c6b4036914f8b1'
                };

                if(w.chaport)return;v3=w.chaport={};v3._q=[];v3._l={};v3.q=function(){v3._q.push(arguments)};v3.on=function(e,fn){if(!v3._l[e])v3._l[e]=[];v3._l[e].push(fn)};var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://app.chaport.com/javascripts/insert.js';var ss=d.getElementsByTagName('script')[0];ss.parentNode.insertBefore(s,ss)})(window, document);
                `}</script>
                <link rel="stylesheet" href="//cdn.jsdelivr.net/chartist.js/latest/chartist.min.css"></link>
            </Helmet>
            <App />
        </SettingsProvider>
    </Provider>,
    document.getElementById("root")
);

if(process.env.NODE_ENV === 'production') {
    hotjar.initialize(3082636, 6);
}

serviceWorker.register();
