import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    run: false,
    currentStep: 0,
};

const slice = createSlice({
    name: "tour",
    initialState,
    reducers: {
        setCurrentStep(state, action) {
            state.currentStep =  action.payload;
        },
        setRun(state, action) {
            state.run = action.payload;;
        },
    },
});

export const reducer = slice.reducer;

export const setCurrentStep = (index) => dispatch => {
    dispatch(slice.actions.setCurrentStep(index));
};

export const setRun = (run) => dispatch => {
    dispatch(slice.actions.setRun(run));
};
