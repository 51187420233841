import React, { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Button,
    Collapse,
    ListItem,
    makeStyles,
    Tooltip
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const useStyles = makeStyles((theme) => ({
    item: {
        display: 'block',
        paddingTop: 0,
        paddingBottom: 0
    },
    itemLeaf: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0
    },
    button: {
        color: theme.palette.text.secondary,
        padding: '10px 8px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%'
    },
    buttonLeaf: {
        color: theme.palette.text.secondary,
        padding: '10px 8px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
        fontWeight: theme.typography.fontWeightRegular,
        '&.depth-0': {
            '& $title': {
                fontWeight: theme.typography.fontWeightMedium
            }
        },
        height: `calc(20px + ${theme.typography.button.fontSize} * ${theme.typography.button.lineHeight})` 
    },
    fullWidthButton: {
        marginLeft: '-16px',
        paddingLeft: '24px !important',
        minWidth: 'calc(100% + 32px)'
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(1),
        width: 20,
        height: 20
    },
    title: {
        marginRight: 'auto'
    },
    active: {
        color: theme.palette.secondary.main,
        '& $title': {
            fontWeight: theme.typography.fontWeightMedium
        },
        '& $icon': {
            color: theme.palette.secondary.main
        }
    }
}));

const NavItem = ({
    children,
    className,
    depth,
    href,
    icon: Icon,
    info: Info,
    open: openProp,
    title,
    showTitle,
    ...rest
}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(openProp);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    let paddingLeft = 8;

    if (depth > 0) {
        paddingLeft = 32 + 8 * depth;
    }

    const style = { paddingLeft };

    const firstLevelButton = (
        <Button
            activeClassName={classes.active}
            className={clsx(classes.buttonLeaf, `depth-${depth}`, !open && classes.fullWidthButton)}
            component={RouterLink}
            exact
            style={style}
            to={href}
        >
            {Icon && (
                <Icon
                    className={classes.icon}
                    size="20"
                />
            )}
            {showTitle && (
                <span className={classes.title}>
                    {title}
                </span>
            )}
            {Info && <Info />}
        </Button>
    );

    if (children) {
        return (
            <ListItem
                className={clsx(classes.item, className)}
                disableGutters
                key={title}
                {...rest}
            >
                <Button
                    className={classes.button}
                    onClick={handleToggle}
                    style={style}
                >
                    {Icon && (
                        <Icon
                            className={classes.icon}
                            size="20"
                        />
                    )}
                    {showTitle && (
                        <span className={classes.title}>
                            {title}
                        </span>
                    )}
                    {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Button>
                <Collapse in={open}>
                    {children}
                </Collapse>
            </ListItem>
        );
    }

    return (
        <ListItem
            className={clsx(classes.itemLeaf, className)}
            disableGutters
            key={title}
            {...rest}
        >
            {!showTitle ? (
                <Tooltip
                    title={title}
                    placement="right"
                >
                    {firstLevelButton}
                </Tooltip>
            ) : firstLevelButton}
        </ListItem>
    );
};

NavItem.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    depth: PropTypes.number.isRequired,
    href: PropTypes.string,
    icon: PropTypes.elementType,
    info: PropTypes.elementType,
    open: PropTypes.bool,
    title: PropTypes.string.isRequired,
    showTitle: PropTypes.bool
};

NavItem.defaultProps = {
    open: false,
    showTitle: true
};

export default NavItem;
