import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import useAuth from "../hooks/useAuth";

const AuthGuard = ({ children }) => {
    const location = useLocation();
    const { isAuthenticated } = useAuth();

    if (!isAuthenticated) {
        return (
            <Redirect
                to={{
                    pathname: "/login",
                    state: { referrer: location.pathname },
                }}
            />
        );
    } 
    
    return <>{children}</>;
};

AuthGuard.propTypes = {
    children: PropTypes.node,
};

export default AuthGuard;
