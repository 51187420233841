import React, { useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
    Avatar,
    Box,
    ButtonBase,
    Hidden,
    Menu,
    MenuItem,
    Typography,
    makeStyles,
} from "@material-ui/core";
import useAuth from "src/hooks/useAuth";

const useStyles = makeStyles((theme) => ({
    avatar: {
        height: 32,
        width: 32,
        marginRight: theme.spacing(1),
    },
    popover: {
        width: 200,
    },
    userName: {
        color: theme.palette.primary.main,
        fontSize: 14
    },
}));

const Account = () => {
    const classes = useStyles();
    const history = useHistory();
    const ref = useRef(null);
    const { user, logout } = useAuth();
    const { enqueueSnackbar } = useSnackbar();
    const [isOpen, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleLogout = async () => {
        try {
            handleClose();
            await logout();
            history.push("/");
        } catch (err) {
            console.error(err);
            enqueueSnackbar("Unable to logout", {
                variant: "error",
            });
        }
    };

    return (
        <>
            <Box
                display="flex"
                alignItems="center"
                component={ButtonBase}
                onClick={handleOpen}
                ref={ref}
            >
                <Avatar
                    alt="User"
                    variant="rounded"
                    className={classes.avatar}
                    src={user.avatar}
                />
                <Hidden smDown>
                    <Typography variant="h6" className={classes.userName}>
                        {`${user.firstName} ${user.lastName}`}
                    </Typography>
                </Hidden>
            </Box>
            <Menu
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                keepMounted
                PaperProps={{ className: classes.popover }}
                getContentAnchorEl={null}
                anchorEl={ref.current}
                open={isOpen}
            >
                <MenuItem
                    component={RouterLink}
                    onClick={handleClose}
                    to="/account"
                >
                    Account
                </MenuItem>
                {user.admin && (<MenuItem
                    component={RouterLink}
                    onClick={handleClose}
                    to="/admin"
                >
                    Admin Panel
                </MenuItem>)}
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
        </>
    );
};

export default Account;
