export const stripeConfig = {
    starter: {
        id: process.env.REACT_APP_STRIPE_STARTER_ID,
        pricing: {
            month: {
                EUR: process.env.REACT_APP_STRIPE_STARTER_MONTHLY_EUR,
                USD: process.env.REACT_APP_STRIPE_STARTER_MONTHLY_USD
            },
            year: {
                EUR: process.env.REACT_APP_STRIPE_STARTER_YEARLY_EUR,
                USD: process.env.REACT_APP_STRIPE_STARTER_YEARLY_USD
            }
        }
    },
    pro: {
        id: process.env.REACT_APP_STRIPE_PRO_ID,
        pricing: {
            month: {
                EUR: process.env.REACT_APP_STRIPE_PRO_MONTHLY_EUR,
                USD: process.env.REACT_APP_STRIPE_PRO_MONTHLY_USD
            },
            year: {
                EUR: process.env.REACT_APP_STRIPE_PRO_YEARLY_EUR,
                USD: process.env.REACT_APP_STRIPE_PRO_YEARLY_USD
            }
        }
    },
    publicKey: process.env.REACT_APP_STRIPE_PUBLICKEY
};

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const GOOGLE_CLIENT_SECRET = process.env.REACT_APP_GOOGLE_CLIENT_SECRET;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

export const GOOGLE_RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY; 