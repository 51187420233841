import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';

const initialState = {
  isLoaded: false,
  error: '',
  project: {},
};

const slice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    loadProject(state) {
        state.isLoaded = false;
    },
    projectSuccess(state, action) {
      state.prediction = action.payload;
      state.isLoaded = true;
      state.error = '';
    },
    projectFailure(state, action) {
        state.error = action.payload;
        state.isLoaded = true 
    }
  }
});

export const reducer = slice.reducer;

export const getProject = (id) => async dispatch => {
    try {
        const response = await axios.get(`/api/projects/${id}`);
        dispatch(slice.actions.projectSuccess(response.data));
    } catch (error) {
        dispatch(slice.actions.projectFailure(error));
    }
};
